<template>
  <div id="loginPage">
    <div class="filter-panel">
      <CSSelect style="margin-right: 0;">
        <el-date-picker
          style="width: 182px;"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="请选择开始时间"
          v-model="queryNotice.startDate"
          :editable="false"
        ></el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect>
        <el-date-picker
          type="datetime"
          style="width: 182px;"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="请选择结束时间"
          v-model="queryNotice.endDate"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <input type="text" v-model="queryNotice.title" class="cs-input" placeholder="搜索公告名称">
      <CSSelect style="margin-right: 30px;">
        <select v-model="queryNotice.state">
          <option value="">公告状态不限</option>
          <option :value="order.id" v-for="order in orderStates" :key="order.id">{{order.name}}</option>
        </select>
      </CSSelect>
      <button
        class="btn btn-primary"
        style="width: 50px; height: 30px; padding: 0;"
        @click="getNotice()"
      >
        查询
      </button>
    </div>
    <div class="result-panel">
        <CSTable :thead-top="filterHeight">
            <template v-slot:header>
                <div class="table-header-panel text-right">
                    <div
                        class="btn btn-primary sticky-right"
                        @click="openAnnouncementDialog"
                    >
                        <svg
                            class="icon"
                            aria-hidden="true"
                        >
                            <use xlink:href="#icon-menujiahao"></use>
                        </svg>
                        添加公告
                    </div>
                </div>
            </template>
            <template v-slot:thead>

                <tr>
                    <th>添加时间</th>
                    <th>公告名称</th>
                    <th>发布时间</th>
                    <th>下架时间</th>
                    <th>公告状态</th>
                    <th>操作</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr v-for="item in noticeList" :key="item.id">
                    <td>
                        {{item.createTime}}
                    </td>
                    <td>
                      <span
                          class="allow-click"
                          @click="lookAnnouncement(item.photos, item.url)"
                      >
                        {{item.title}}
                      </span>
                    </td>
                    <td>
                        {{item.releaseTime}}
                    </td>
                    <td>
                        {{item.unshelveTime}}
                    </td>
                    <td>
                        {{orderState[item.state]}}
                    </td>
                    <td>
                        <div class="btn-group">
                            <button
                                type="button"
                                class="btn btn-primary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                操作
                            </button>
                            <ul class="dropdown-menu">
                                <li v-if="item.state == 1 || item.state == 3" @click="putawayAnnouncement = true; putawayId = item.id">
                                    <a>
                                        上架公告
                                    </a>
                                </li>
                                <li v-if="item.state == 2" @click="soldOutAnnouncement = true; soldOutId = item.id">
                                    <a>
                                        下架公告
                                    </a>
                                </li>
                                <li @click="currentItemId = item.id; deleteAnnouncement = true;">
                                    <a>
                                        删除公告
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </template>
        </CSTable>
      <Pagination />
    </div>
    <CSDialog
      :dialog-visible="announcementDialog.visible"
      dialog-width="916px"
      :dialog-title="announcementDialog.title"
      @onClose="announcementDialog.onCancel"
      @onConfirm="announcementDialog.onConfirm"
      dialog-confirm-btn-text="预览"
    >
      <div slot="dialog-content" class="form-panel">
        <div class="form-item">
          <div
            class="form-item-label"
          >
            公告名称
          </div>
          <input
              type="text"
              placeholder="限25字"
              maxlength="25"
              class="hy-input"
              v-model="announcementDialog.data.title"
          >
        </div>
        <div class="form-item">
          <div
            class="form-item-label"
          >
            发布时间
          </div>
          <div class="form-item-content">
            <CSSelect
              iWidth="36px"
              height="40px"
            >
              <el-date-picker
                type="datetime"
                style="width: 264px;"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                placeholder="请选择"
                prefix-icon="el-icon-time"
                v-model="announcementDialog.data.releaseTime"
                :editable="false"
              >
              </el-date-picker>
            </CSSelect>
          </div>
        </div>
        <div class="form-item">
          <div
            class="form-item-label"
          >
            下架时间
          </div>
          <div class="form-item-content">
            <CSSelect
              iWidth="36px"
              height="40px"
            >
              <el-date-picker
                type="datetime"
                style="width: 264px;"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                placeholder="请选择"
                prefix-icon="el-icon-time"
                :editable="false"
                v-model="announcementDialog.data.unshelveTime"
              >
              </el-date-picker>
            </CSSelect>
          </div>
        </div>
        <div class="form-item">
            <div class="form-item-label top">公告内容</div>
            <div class="form-item-content">
                <div style="max-width: 690px;">
                    <div class="photo-view"
                         v-for="(resourceItem, index) in announcementDialog.data.photos" :key="index"
                         :style="{width: '200px', height: '128px', marginBottom: announcementDialog.data.photos.length > 2 ? '10px' : '0'}"
                         draggable @dragover="fileDragOver(index, $event)"
                         @drop="fileDragEnd(index, $event)"
                    >
                        <img :src="resourceItem" alt=""
                             @dragstart="fileDragStart(index, $event)">
                        <i class="icon-close" @click="deleteFile(index)"/>
                    </div>
                    <div class="upload-photo" style="width: 200px;height: 128px;"
                         v-if="announcementDialog.data.photos.length < 1" @click="triggerChooseFile"
                    >
                        <div class="text-center">
                            <img src="@/assets/upload.png" style="margin: 5px auto 5px"/>
                            <p style="color: #999">上传图片</p>
                            <p style="color: #999">尺寸460*300</p>
                        </div>
                    </div>
                </div>
                <input type="file" id="uploadFile"
                       accept="image/gif, image/jpeg, image/png" hidden
                       @change="chooseFile($event)">
            </div>
        </div>
        <div class="form-item">
          <div class="form-item-label">
            跳转链接
          </div>
          <input type="text" v-model="announcementDialog.data.url" class="hy-input" placeholder="请输入">
        </div>
      </div>
    </CSDialog>
    <Monitor :monitorVisible="announcementPreviewDialog.monitorVisible">
        <template v-slot:monitorContent>
            <div style="width: 644px; height:380px; position: absolute; top: 14px; left:14px; background: #fff;">
                <el-carousel trigger="click" height="380px">
                    <el-carousel-item v-for="(item, index) in announcementPreviewDialog.data" :key="index">
                        <img :src="item" alt="" style="width:100%;height:100%;">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </template>
        <template v-slot:btnGroup>
            <template v-if="statePreview">
                <button class="btn btn-primary" style="width:180px;height:40px;font-size:20px;margin-right: 50px;"
                        @click="announcementPreviewDialog.prevStep">上一步
                </button>
                <button :class="['btn', 'btn-primary', {disabled: isSubmitting}]" style="width:180px;height:40px;font-size:20px;"
                        @click="announcementPreviewDialog.onConfirm">提交
                </button>
            </template>
            <button v-else
                    style="display:block; width: 140px; height:40px; line-height: 20px; font-size:20px; margin: 0 auto;"
                    class="btn btn-primary" @click="announcementPreviewDialog.onClose">关闭
            </button>
        </template>
    </Monitor>
      <!-- 上架公告弹出框 -->
      <CSDialog
          dialogTitle="提示" dialog-header-class=" "
          dialogWidth="480px" dialog-confirm-btn-text="确定"
          :dialogVisible="putawayAnnouncement"
          @onClose="putawayAnnouncement = false"
          @onConfirm="putawayNotice"
          dialog-header-icon="icon-menua-zu13"
      >
          <div
              slot="dialog-content"
              style="padding: 20px 30px; font-size: 24px; text-align: center;"
          >
              确定上架吗?
          </div>
      </CSDialog>
      <!-- 下架公告弹出框 -->
      <CSDialog
          dialogTitle="提示" dialog-header-class=" "
          dialogWidth="480px" dialog-confirm-btn-text="确定"
          :dialogVisible="soldOutAnnouncement"
          @onClose="soldOutAnnouncement = false"
          @onConfirm="soldOutNotice"
          dialog-header-icon="icon-menua-zu13"
      >
          <div
              slot="dialog-content"
              style="padding: 20px 30px; text-align: center; font-size: 24px;"
          >
              确定下架吗?
          </div>
      </CSDialog>
      <!-- 删除公告弹出框 -->
      <CSDialog
          dialogTitle="提示" dialog-header-class=" "
          dialogWidth="480px" dialog-confirm-btn-text="确定"
          :dialogVisible="deleteAnnouncement"
          @onClose="deleteAnnouncement = false"
          @onConfirm="DeleteAnnouncement"
          dialog-header-icon="icon-menua-zu13"
      >
          <div
              slot="dialog-content"
              style="padding: 20px 30px; font-size: 24px; text-align: center;"
          >
              确定删除吗?
          </div>
      </CSDialog>
      <CSDialog
          dialog-title="查看图片"
          :dialog-show-confirm-btn="false"
          dialog-cancel-btn-text="关闭"
          :dialog-visible="lookNoticeVisible"
          @onClose="lookNoticeVisible = false"
          dialog-width="648px"
      >
            <template v-slot:dialog-content>
                <div
                    style="padding: 20px 30px; font-size: 24px; text-align: center;"
                >
                    <img :src="lookNoticeImgUrl" style="width: 588px; height: 328px; margin-bottom: 20px" alt="">
                    <div style="font-size: 18px; width: 588px;  overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">跳转链接: <a :href="lookNoticeUrl" target="_blank">{{lookNoticeUrl}}</a></div>
                </div>
            </template>
      </CSDialog>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import CSDialog from "@/components/common/CSDialog";
import Monitor from "@/components/Monitor";
import dayjs from "dayjs";

import {
    getAdminQueryUrl,
    commonImgOssDomain,
    addPCLoginUrl,
    adminIsEnableUrl,
    adminDelUrl,
} from '@/requestUrl';
import CSTable from "@/components/common/CSTable";

export default {
  name: "loginPage",
  components: {
      CSTable,
    CSSelect,
    Pagination,
    CSDialog,
    Monitor,
  },
  created(){
    window.addEventListener("keydown",this.getNoticeDown);
  },
  beforeDestroy(){
    window.removeEventListener("keydown",this.getNoticeDown);
  },
  deactivated(){
       window.removeEventListener("keydown",this.getNoticeDown);
  },
  data() {
    return {
        isSubmitting: false,
        filterHeight: 0,
      // 查询状态
      orderStates: [
          { id: 1, name: "未上架" },
          { id: 2, name: "已上架" },
          { id: 3, name: "已下架" },
      ],
      // 存放选择状态
      orderState: {
          1: "未上架",
          2: "已上架",
          3: "已下架",
      },
      // 查询公告
      queryNotice: {
        regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
        startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00", //查询开始时间 必填
        endDate: dayjs().format("YYYY-MM-DD") + " 23:59", //查询结束时间 必填
        title: '', //公告名称
        state: '', //公告状态 1.未上架2.已上架3.已下架
        pageNo: 1,
        pageSize: 10,
        type: 2,
      },
      //后台登录页列表
      noticeList: null,
      announcementPreviewDialog: {
          visible: false,
          monitorVisible: false,
          data: [],
          prevStep: () => {
              this.announcementPreviewDialog.monitorVisible = false;
              this.announcementDialog.visible = true;
              this.statePreview = false;
          },
          onConfirm: () => {
          },
          onClose: () => {
              this.announcementPreviewDialog.monitorVisible = false;
              this.statePreview = false;
          }
      },
      announcementDialog: {
          visible: false,
          title: '添加公告',
          data: {
              regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
              title: "", //公告名称 必填
              releaseTime: '', //发布时间 必填
              unshelveTime: '', //下架时间 必填
              url: '', //跳转链接 必填
              photos: [], //公告图片
              userId: this.$vc.getCurrentStaffInfo().id, //员工id 必填
          },
          onCancel: () => {
              this.announcementDialog.visible = false;
          },
          onConfirm: () => {
              if (this.announcementDialog.data.photos.length === 0) {
                  this.$vc.toast('请上传配图');
                  return;
              }
              this.announcementPreviewDialog.data = this.announcementDialog.data.photos;
              this.announcementPreviewDialog.monitorVisible = true;
              this.announcementDialog.visible = false;
              this.statePreview = true;
          }
      },
        putawayAnnouncement: false,
        soldOutAnnouncement: false,
        deleteAnnouncement: false,
        putawayId: '',
        soldOutId: '',
        currentItemId: '',
        lookNoticeVisible: false,
        lookNoticeImgUrl: '',
        lookNoticeUrl: '',
        statePreview: false,
    }
  },
  mounted() {
      this.getNotice();
      this.$vc.on(this.$route.path, 'pagination_page', 'event', page => {
          this.getNotice(page);
      })
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      window.addEventListener('resize', () => {
          this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      })
  },
  methods: {
    getNoticeDown(e){
      if(e.keyCode == 13){
        this.getNotice();
      }
    },
      async getNotice(pageNo = 1, pageSize = 10) {
          let res = await this.$fly.post(getAdminQueryUrl, {
              ...this.queryNotice,
              pageNo,
              pageSize,
          });
          if (res.code != 0) {
              return;
          }
          this.noticeList = res.data.datas;
          if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                  total: res.data.total,
                  currentPage: pageNo,
                  pageSize,
              });
          }
      },
      // 查看图片
      lookAnnouncement(photos, url) {
          this.lookNoticeImgUrl =  JSON.parse(photos)[0];
          this.lookNoticeUrl = url;
          this.lookNoticeVisible = true;
      },
      /**
       * 删除选中的文件资源
       * @param {Number} index 文件路径所在下标
       * */
      deleteFile(index) {
          this.announcementDialog.data.photos.splice(index, 1);
      },
      // 打开添加公告弹窗
      openAnnouncementDialog() {
          this.announcementDialog.data = {
              regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
              title: "", //公告名称 必填
              releaseTime: '', //发布时间 必填
              unshelveTime: '', //下架时间 必填
              url: '', //跳转链接 必填
              photos: [], //公告图片
              userId: this.$vc.getCurrentStaffInfo().id, //员工id 必填
          }
          this.announcementDialog.title = '添加公告';
          this.announcementPreviewDialog.onConfirm = () => {
              if (this.isSubmitting) {
                  this.$vc.message('请不要重复提交');
                  return ;
              }
              if (this.advertisingCount + this.announcementDialog.data.photos.length < 2) {
                  this.$vc.message('至少选择两个广告');
                  return;
              }
              let params = {
                  ...this.announcementDialog.data,
                  photos: JSON.stringify(this.announcementDialog.data.photos),
              }
              this.isSubmitting = true;
              this.$fly.post(addPCLoginUrl, params)
                  .then(res => {
                      if (res.code !== 0 ) {
                          return;
                      }
                      this.$vc.toast('添加成功');
                      this.clearFormData();
                      this.announcementPreviewDialog.monitorVisible = false;
                      this.getNotice();
                  })
                  .finally(() => this.isSubmitting = false)
          }
          this.announcementDialog.visible = true;
      },

      //  调起选择文件
      triggerChooseFile() {
          $("#uploadFile").trigger("click");
      },
      // 选择文件后处理
      async chooseFile(event) {
          console.log(111)
          let file = event.target.files[0];
          if (!file.type.includes('image')) {
              this.$vc.toast("只可上传图片");
              return false;
          }
          this.speedProgressVisible = true
          file.needEncodeURI = true;
          // this.$fly
          //     .putFile(file, (ossObject) => {
          //         this.speedProgress = ossObject.progress;
          //         this.ossClient = ossObject.ossClient;
          //     })
          //     .then(res => {
          //         this.speedProgressVisible = false;
          //         if (file.size / 1024 / 1024 > 100) {
          //             res.url = res.url.replace('http://cosmos-img.oss-cn-beijing.aliyuncs.com/', commonImgOssDomain);
          //         }
          //         this.announcementDialog.data.photos.push(res.url)
          //     },)
          let formData = new FormData(); //新建FormData对象
          formData.append("files", event.target.files[0]);
          formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
          formData.append("compress", "true");
          const photo = await this.Constants.uploadFileOne(formData);
          if (photo) {
              this.announcementDialog.data.photos.push(this.Constants.BUCKET_NAMES.IMG.visitUrl + photo);
          }
          event.target.value = "";
      },
      // 取消上传
      cancelUpData() {
          this.ossClient.terminationUpload();
          this.speedProgressVisible = false;
          this.speedProgress = 0;
      },
      clearFormData() {
          this.announcementDialog.data = {
              regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
              title: "", //公告名称 必填
              releaseTime: '', //发布时间 必填
              unshelveTime: '', //下架时间 必填
              url: '', //跳转链接 必填
              photos: [], //公告图片
              userId: this.$vc.getCurrentStaffInfo().id, //员工id 必填
          };
      },
      // 添加公告
      async addNotice() {
           let res = await this.$fly.post(addPCLoginUrl, this.announcementDialog.data);
           if (res.code != 0) {
               this.$vc.toast('添加失败');
               return;
           }
      },
      // 后台上架公告
      putawayNotice() {
          this.putawayAnnouncement = true;
          this.$fly
              .post(adminIsEnableUrl, {
                  regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                  id: this.putawayId, //公告id 必填
                  state: 2, //2.上架3.下架 必填
              })
              .then((res) => {
                  if (res.code != 0) {
                      return;
                  }
                  this.$vc.toast('上架成功');
                  this.putawayAnnouncement = false;
                  this.getNotice();
              });
      },
      // 后台下架公告
      soldOutNotice() {
          this.soldOutAnnouncement = true;
          this.$fly
              .post(adminIsEnableUrl, {
                  regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                  id: this.soldOutId, //公告id 必填
                  state: 3, //2.上架3.下架 必填
              })
              .then((res) => {
                  if (res.code != 0) {
                      this.$vc.toast('下架失败');
                      return;
                  }
                  this.$vc.toast('下架成功');
                  this.soldOutAnnouncement = false;
                  this.getNotice();
              });
      },
      // 删除公告
      DeleteAnnouncement() {
          this.deleteAnnouncement = true;
          this.$fly
              .post(adminDelUrl, {
                  regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                  id: this.currentItemId, //公告id 必填
                  userId: this.$vc.getCurrentStaffInfo().id, //操作人id 必填
              })
              .then((res) => {
                  if (res.code != 0) {
                      return;
                  }
                  this.deleteAnnouncement = false;
                  this.getNotice();
              });
      },
  }
}
</script>

<style lang="stylus" scoped>
.form-panel
    padding 27px 30px 30px 30px
    .form-item
        margin-bottom 25px
        font-size 0

        &-label
            text-align right
            width 120px
            display inline-block
            margin-right 40px
            vertical-align middle
            font-size 24px

            &.top
                vertical-align top

        &-content
            display inline-block
            vertical-align middle
            font-size 24px

            .cs-input
                &::placeholder
                    color #999
                padding-left 10px
                border-radius 4px
                margin 0
                border 1px solid #979797

            /deep/ .el-input__inner
                font-size 24px

                &::placeholder
                    color #999

            /deep/ .el-input__prefix
                font-size 24px
                line-height 20px

.table-title
      width auto
.form-item
    margin-bottom 25px
    font-size 0
    &-label
        text-align right
        width 96px
        display inline-block
        margin-right 40px
        vertical-align middle
        font-size 24px

        &.top
            vertical-align top
    &-content
        display inline-block
        vertical-align middle
        font-size 24px

</style>
